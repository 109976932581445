<template>
  <div id="AliPayHK">
    <form
      action="https://payment.pa-sys.com/app/page/a6fb62d9-2eda-4745-a09a-fe26391a4eea"
      name="payment"
      method="POST"
      accept-charset="utf-8"
      ref="alipay-form"
    >
      <input type="hidden" name="amount" :value="form.amount" />
      <input type="hidden" name="currency" :value="form.currency" />
      <input type="hidden" name="customer_address" :value="form.customer_address" />
      <input type="hidden" name="customer_country" :value="form.customer_country" />
      <input type="hidden" name="customer_email" :value="form.customer_email" />
      <input type="hidden" name="customer_ip" :value="form.customer_ip" />
      <input type="hidden" name="customer_first_name" :value="form.customer_first_name" />
      <input type="hidden" name="customer_last_name" :value="form.customer_last_name" />
      <input type="hidden" name="customer_phone" :value="form.customer_phone" />
      <input type="hidden" name="customer_state" :value="form.customer_state" />
      <input type="hidden" name="merchant_reference" :value="form.merchant_reference" />
      <input type="hidden" name="return_url" :value="form.return_url" />
      <input type="hidden" name="network" :value="form.network" />
      <input type="hidden" name="subject" :value="form.subject" />
      <input type="hidden" name="sign" :value="form.sign" />
    </form>
  </div>
</template>
<script>
export default {
  name: 'AliPayHK',
  data: function () {
    return {
      form: {
        amount: null,
        currency: null,
        customer_address: null,
        customer_country: null,
        customer_email: null,
        customer_ip: null,
        customer_first_name: null,
        customer_last_name: null,
        customer_phone: null,
        customer_state: null,
        merchant_reference: null,
        return_url: null,
        network: null,
        subject: null,
        sign: null,
      },
    }
  },
  created: function () {
    var self = this

    let order_code = this.$route.query.order_code
    if (!order_code) return

    self.common
      .httpPost('/api/ajax/aliPayHkOrder', {
        order_code: order_code,
      })
      .then((ret) => {
        if (ret.code == 1) {
          self.form = ret.data
          self.$nextTick(function () {
            self.$refs['alipay-form'].submit()
          })
        } else self.common.showMessage(ret.msg, 'error')
      })
  },
}
</script>

<style>
</style>
